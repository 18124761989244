import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { EmbedAspectRatios, EmbedBasic } from "../../examples/utilities/Embed";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 id="about" className="h2 font-weight-bold mt-3">
  About
  <a className="header-link text-light text-decoration-none font-weight-normal" href="#about" aria-label="anchor"></a>
    </h3>
    <p>
  Rules are directly applied to <code>&lt;iframe&gt;</code>,{" "}
  <code>&lt;embed&gt;</code>,<code>&lt;video&gt;</code>, and{" "}
  <code>&lt;object&gt;</code> elements; optionally use an explicit descendant
  class <code>.embed-responsive-item</code> when you want to match the styling
  for other attributes.
    </p>
    <p>
  <strong>Pro-Tip!</strong> You don’t need to include{" "}
  <code>frameborder-"0"</code> in your
  <code>&lt;iframe&gt;</code> as we override that for you.
    </p>
    <CodeBlock title="Example" code={EmbedBasic} mdxType="CodeBlock">
  Wrap any embed like an <code>&lt;iframe&gt;</code> in a parent element with{" "}
  <code>.embed-responsive</code> and an aspect ratio. The{" "}
  <code>.embed-responsive-item </code>isn’t strictly required, but we encourage
  it.
    </CodeBlock>
    <h3 id="aspect-ratios" className="h2 font-weight-bold mt-3">
  Aspect Ratios
  <a className="header-link text-light text-decoration-none font-weight-normal" href="#aspect-ratios" aria-label="anchor"></a>
    </h3>
    <p>
  Aspect ratios can be customized with modifier classes. By default, the
  following ratio classes are provided:
    </p>
    <div className="highlight">
  <pre tabIndex={0} className="chroma">
    <code className="language-text" data-lang="text">
      {EmbedAspectRatios.trim()}
    </code>
  </pre>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      